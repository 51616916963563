import { useState, useEffect, useCallback } from 'react';

const getCookie = () => {
  const name = 'cookiepolicy=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookie = decodedCookie.split('; ');
  for (let i = 0; i < cookie.length; i += 1) {
    if (cookie[i].indexOf(name) > -1) {
      return cookie[i].substring(name.length, cookie[i].length);
    }
  }
  return '';
};

const initializeState = () => {
  if (document.cookie.indexOf('cookiepolicy') === -1) {
    return {
      required: true,
      analytics: false,
      profile: false,
    };
  }
  const currentCookie = getCookie().split(',');
  return currentCookie.reduce((state, cookieType) => {
    if (['required', 'analytics', 'profile'].indexOf(cookieType) !== -1) {
      state[cookieType] = true;
    }
    return state;
  }, {});
};

export const useCookies = () => {
  const [isLoaded, setLoaded] = useState(false);
  const [preferences, setPreferences] = useState(initializeState);

  const createCookie = useCallback(() => {
    const keys = Object.keys(preferences);
    const newValue = keys.reduce((accumulator, cookieType) => {
      if (preferences[cookieType]) {
        accumulator.push(cookieType);
      }
      return accumulator;
    }, []);
    // Imposto la scadenza del cookie
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10);
    return `cookiepolicy=${newValue.join(',')};expires=${expires.toUTCString()};path=/`;
  }, [preferences]);

  useEffect(() => {
    if (isLoaded) {
      document.cookie = createCookie();
    }
    setLoaded(true);
  }, [preferences]);

  return [
    preferences,
    setPreferences,
  ];
};

const cachedScripts = [];
export const useCookieScript = (preferences) => {
  useEffect(() => {
    if (preferences.profile && !cachedScripts.includes(`${process.env.PUBLIC_URL}/js/d360_snow.js`)) {
      cachedScripts.push(`${process.env.PUBLIC_URL}/js/d360_snow.js`);
      // Create script
      const script = document.createElement('script');
      script.src = `${process.env.PUBLIC_URL}/js/d360_snow.js`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [preferences.profile]);
};

export default {
  useCookies,
  useCookieScript,
};
