import React, { useEffect, useState, useContext } from 'react';
import API from '@aws-amplify/api';
import * as Sentry from '@sentry/react';
import { Cache } from 'aws-amplify';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Columns, Heading } from 'react-bulma-components';

const PartnerImg = styled.img`
  max-height: 56px;
`;

const CustomColumn = styled(({ noMarginBottom, ...props }) => <Columns {...props} />)`
  &.columns:not(last-child) {
    margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? 0 : null)}
  }
  margin-top: 0;
`;

const Partner = ({
  image, altText,
}) => {
  const link = false;
  // if (hasStand) {
  //   link = `/partners/${slugify(name, { lower: true, remove: /[*+~.()'"!:@–,]/g })}`;
  // }
  return (
    <Columns.Column
      className="has-text-centered is-6-touch"
      desktop={{ size: 'one-fifth' }}
    >
      {link ? (
        <a href={link} rel="noreferrer">
          <PartnerImg src={image} alt={altText} />
        </a>
      ) : (
        <PartnerImg src={image} alt={altText} />
      ) }
    </Columns.Column>
  );
};

Partner.propTypes = {
  image: PropTypes.string.isRequired,
  altText: PropTypes.string,
};

Partner.defaultProps = {
  altText: '',
};

const fetchPartners = async () => {
  let response = [];
  try {
    response = Cache.getItem('expositionFooterPartners');
    if (response) {
      return response;
    }
    response = await API.get('ForgesAPI', '/exposition_partners', {});
    response.reverse();
    const cacheExpiration = new Date();
    cacheExpiration.setTime(cacheExpiration.getTime() + (1000 * 60 * 15));
    if (response != null) {
      Cache.setItem('expositionFooterPartners', response, { expires: cacheExpiration.getTime() });
    }
  } catch (error) {
    if (error.response) {
      Sentry.captureException(error, {
        extra: {
          response: error.response.data,
        },
      });
    } else if (error.request) {
      console.error('fetchPartners failed.');
      Sentry.captureException(error, {
        extra: {
          axiosConf: error.config,
          headers: error.config.headers,
          dataSent: error.config.data,
          token: error.config.headers.Authorization,
        },
      });
    } else {
      Sentry.captureException(error);
    }
    // Ritorno un array vuoto dei partners
    return [];
  }
  return response;
};

const PartnerArea = () => {
  const theme = useContext(ThemeContext);
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const allPartners = await fetchPartners();
      if (allPartners && allPartners.length > 0) {
        setPartners(allPartners);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <CustomColumn style={{ marginBottom: '0.75rem' }}>
        <Columns.Column size={2} className="has-text-centered-touch">
          <Heading style={{ height: '56px', lineHeight: '56px', color: theme.colors.gradient2 }} size={4} renderAs="p">PARTNER</Heading>
        </Columns.Column>
        <Columns.Column>
          <Columns className="is-vcentered is-mobile">
            { partners.map(
              (partner) => (
                <Partner
                  key={partner.id}
                  image={partner.url_logo}
                  hasStand={partner.stand_virtuale === '1'}
                  urlStand={partner.url_stand_virtuale}
                  altText={partner.name}
                  name={partner.name}
                />
              ),
            )}
          </Columns>
        </Columns.Column>
      </CustomColumn>
    </>
  );
};
export default PartnerArea;
