import React, { useState, useEffect } from 'react';
import { Auth, Hub, Cache } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import UserContext from './userContext';
import Authenticator from './authenticator';

const withAuth = (Component) => {
  const unprotectedPaths = [
    '/partners',
  ];
  function WrappedComponent(props) {
    const [authState, setAuthState] = useState({
      currentUser: {},
      isLoaded: false,
      userGroups: [],
    });

    const getUserGroups = (user) => user.signInUserSession.getIdToken().payload['cognito:groups'];
    const updateCurrentUser = async (user) => {
      if (user) {
        setAuthState({ currentUser: user });
        return;
      }
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        setAuthState({
          currentUser: authenticatedUser,
          isLoaded: true,
          userGroups: getUserGroups(authenticatedUser),
        });
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: authenticatedUser.signInUserSession.idToken.payload.profilo_user,
            username: authenticatedUser.username,
            userGroups: authenticatedUser.signInUserSession.idToken.payload['cognito:groups'],
          });
        });
      } catch (err) {
        if (err !== 'not authenticated') {
          Sentry.captureException(err);
        }
        setAuthState({ currentUser: null, isLoaded: true, userGroups: [] });
      }
    };

    useEffect(() => {
      const onAuthEvent = ({ channel, payload }) => {
        if (channel === 'auth' && payload.event === 'signOut') {
          Cache.removeItem('NICKNAME');
        }

        if (channel === 'auth' && payload.event !== 'signIn') {
          updateCurrentUser();
        }
      };
      const asyncOperation = async () => {
        await updateCurrentUser();
      };
      asyncOperation();
      Hub.listen('auth', onAuthEvent);
    }, []);

    if (unprotectedPaths.includes(window.location.pathname)) {
      return (
        <UserContext.Provider value={{
          user: authState.currentUser,
          updateCurrentUser,
          isLoaded: authState.isLoaded,
          userGroups: authState.userGroups,
        }}
        >
          <Component {...props} />
        </UserContext.Provider>
      );
    }

    if (!authState.currentUser) {
      return (
        <UserContext.Provider value={{
          user: authState.currentUser,
          updateCurrentUser,
          isLoaded: authState.isLoaded,
          userGroups: authState.userGroups,
        }}
        >
          <Authenticator />
        </UserContext.Provider>
      );
    }

    return (
      <UserContext.Provider value={{
        user: authState.currentUser,
        updateCurrentUser,
        isLoaded: authState.isLoaded,
        userGroups: authState.userGroups,
      }}
      >
        <Component {...props} />
      </UserContext.Provider>
    );
  }
  return WrappedComponent;
};

export default withAuth;
