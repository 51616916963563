import React from 'react';
import PropTypes from 'prop-types';
import Button from './cookieBarButton';

const CookieOptionsHeading = ({
  onAccept,
  onRefuse,
}) => (
  <div className="cookie-bar__options__head">
    <div className="cookie-bar__options__head__header-desktop hide-on-med-and-down">
      <h3 className="cookiebar-text-semibold cookiebar-text-size-1">Cookie Center</h3>
      <div className="cookie-bar__options__head__buttons-desktop">
        <Button outlined onClick={onRefuse}>RIFIUTA TUTTO</Button>
        <Button onClick={onAccept}>ACCETTA TUTTO</Button>
      </div>
    </div>
    <p>
      { `Tramite il nostro Cookie Center, l'utente ha la possibilità di
      selezionare/deselezionare le singole categorie di cookie che sono
      utilizzate sui siti web.` }
    </p>
    <p>
      Per ottenere maggiori informazioni sui cookie utilizzati, è comunque
      possibile visitare la nostra
      <a
        href="https://www.networkdigital360.it/cookie"
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        <strong> COOKIE POLICY</strong>
      </a>
      .
    </p>
    <div className="cookie-bar__options__head__buttons-small hide-on-desktop">
      <Button onClick={onAccept}>ACCETTA TUTTO</Button>
      <Button onClick={onRefuse} outlined>RIFIUTA TUTTO</Button>
    </div>
  </div>
);

export default CookieOptionsHeading;

CookieOptionsHeading.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onRefuse: PropTypes.func.isRequired,
};
