import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Navbar } from 'react-bulma-components';

const signOut = () => {
  Auth.signOut();
};

const ProfileMenu = () => {
  const [userFullName, setName] = useState('');
  useEffect(() => {
    const fetchUser = async () => {
      const session = await Auth.currentSession();
      const payload = session.getIdToken().decodePayload();
      setName(`${payload.name} ${payload.surname}`);
    };
    fetchUser();
  }, []);
  return (
    <Navbar.Item hoverable className="is-flex" href="#">
      <Navbar.Link className="has-text-white is-size-7-mobile has-text-weight-bold is-flex">{ userFullName }</Navbar.Link>
      <Navbar.Dropdown>
        <Navbar.Item className="has-text-weight-bold" onClick={signOut}>Logout</Navbar.Item>
      </Navbar.Dropdown>
    </Navbar.Item>
  );
};

export default ProfileMenu;
