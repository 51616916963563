import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import withAuth from './Auth/withAuthHOC';
import Navbar from './components/navbar';
import AppTheme from './themes/theme';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
// const Sponsor = React.lazy(() => import('./pages/Sponsor'));
const Moderatore = React.lazy(() => import('./pages/Moderatore'));
const Relatore = React.lazy(() => import('./pages/Relatore'));
// const Partners = React.lazy(() => import('./pages/Partners'));
const App = () => (
  <ThemeProvider theme={AppTheme}>
    <Router>
      <Navbar />
      <Suspense fallback={<div>Loading</div>}>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          {/* <Route exact path="/partners/:slug" component={Sponsor} /> */}
          <Route exact path="/moderatore" component={Moderatore} />
          <Route exact path="/relatore" component={Relatore} />
          {/* <Route exact path="/partners" component={Partners} /> */}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  </ThemeProvider>
);

export default withAuth(App);
