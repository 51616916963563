import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import memoizeFormikForms from '../../helpers/memoForms';
import Input from './input';

const { Field } = Form;

const fields = [
  'fpanet_email',
  'fpanet_password',
  'fpanetConfermaPassword',
];

const DatiAccesso = ({ formik }) => (
  <>
    <Field horizontal>
      <div className="field-body">
        <Input
          id="fpanet_email"
          name="fpanet_email"
          label="Email"
          placeholder="Email"
          half
          error={formik.touched.fpanet_email && formik.errors.fpanet_email}
          errorText={formik.errors.fpanet_email}
          {...formik.getFieldProps('fpanet_email')}
        />
      </div>
    </Field>
    <Field horizontal>
      <div className="field-body">
        <Input
          id="fpanet_password"
          name="fpanet_password"
          type="password"
          label="Password"
          placeholder="Password"
          error={formik.touched.fpanet_password && formik.errors.fpanet_password}
          errorText={formik.errors.fpanet_password}
          {...formik.getFieldProps('fpanet_password')}
        />
        <Input
          id="fpanetConfermaPassword"
          name="fpanetConfermaPassword"
          type="password"
          label="Conferma password"
          placeholder="Conferma password"
          error={formik.touched.fpanetConfermaPassword && formik.errors.fpanetConfermaPassword}
          errorText={formik.errors.fpanetConfermaPassword}
          {...formik.getFieldProps('fpanetConfermaPassword')}
        />
      </div>
    </Field>
  </>
);

DatiAccesso.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.object,
    getFieldProps: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
  }).isRequired,
};

const memoizedFunction = memoizeFormikForms(fields);

export default React.memo(DatiAccesso, memoizedFunction);
