import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';

const {
  Field,
  Control,
  Label,
  Input: BulmaInput,
  Help,
} = Form;

/**
 * TODO i 5px mi servono per allineare la select rispetto ai campi sopra,
 * bisogna capire da dove esce fuori
 */
const StyledField = styled(Field)`
  @media(min-width: 769px) {
    flex-basis: calc(50% - 5px);
`;

const Input = ({
  label,
  name,
  error,
  errorText,
  half,
  innerLabel,
  ...props
}) => (
  <StyledField className={half ? 'is-narrow' : null} half={half ? 1 : 0}>
    {!innerLabel && (<Label className="is-family-primary">{ label }</Label>)}
    <Control>
      {innerLabel && (<Label className="is-family-primary">{ label }</Label>)}
      <BulmaInput className="is-radiusless" color={error ? 'danger' : null} colorname={name} {...props} />
    </Control>
    {error ? (<Help color="danger">{errorText}</Help>) : null}
  </StyledField>
);

export default Input;

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorText: PropTypes.string,
  innerLabel: PropTypes.bool,
  half: PropTypes.bool,
};

Input.defaultProps = {
  error: '',
  errorText: '',
  innerLabel: false,
  half: false,
};
