import React, { useCallback, useState } from 'react';
import { Navbar as BulmaNavbar } from 'react-bulma-components';
import styled from 'styled-components';
import { useEventListener } from '../helpers/hooks';
import Percorso1 from '../assets/images/percorso1.png';

const StyledNavbar = styled(({ shrink, ...props }) => <BulmaNavbar {...props} />)`

  display: ${({ shrink }) => (shrink ? 'flex' : 'block')};
  max-height: ${({ shrink }) => (shrink ? '52px' : null)};

  @media (max-width: 401px) {
    padding: ${({ shrink }) => (shrink ? '0 0 0 0.75rem' : null)};
  }

  @media (min-width: 769px) {
    max-height: ${({ shrink }) => (shrink ? '61px' : '84px')};
    display: flex;
  }

  &,
  .navbar-brand .navbar-item {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .navbar-brand {
    flex-shrink: 0;
  }

  @media (min-width: 769px) {
    transition: max-height 0.2s ease-in-out;
    height: 300px;
  }

  // altezza della navbar principale
  &.is-fixed-top {
    top: 98px;
  }

  .navbar-item {
    @media (max-width: 400px) {
      padding: ${({ shrink }) => (shrink ? '0.5rem 0' : null)};
    }
  }

  .navbar-brand {
    @media (max-width: 768px) {
      display: ${({ shrink }) => (shrink ? null : 'block')};
      text-align: ${({ shrink }) => (shrink ? null : 'center')};
    }

    .navbar-item {
      flex-shrink: 1;
      @media (min-width: 769px) {
        flex-shrink: 0;
      }
    }
  }

  .text {
    width: 100%;
  }

  .text strong {
    font-size: ${({ shrink }) => (shrink ? '0.75rem' : '1.25rem')};
    @media (min-width: 769px) {
      font-size: ${({ shrink, theme }) => (shrink ? theme.sizes.medium : '1.25rem')};
    }
  }

  .text p {
    font-size: ${({ shrink, theme }) => (shrink ? '0.625rem' : theme.sizes.medium)};
    @media (min-width: 769px) {
      font-size: ${({ shrink, theme }) => (shrink ? '0.875rem' : theme.sizes.medium)};
    }
  }

  .navbar-menu,
  .navbar-end {
    display: flex;
  }

  .navbar-menu {
    padding: 0;
    justify-content: ${({ shrink }) => (!shrink ? 'center' : null)};
  }

  .navbar-menu {
    @media screen and (max-width: 1023px) {
      flex-grow: 1;
      background-color: transparent
    }

  .navbar-end {
    margin-left: ${({ shrink }) => (shrink ? 'auto' : null)};
    justify-content: ${({ shrink }) => (shrink ? 'flex-end' : null)};
    @media (min-width: 769px) {
      margin-left: auto;
      justify-content: flex-end;
    }
  }


  .navbar-end .navbar-item {
    padding: ${({ shrink }) => (shrink ? '4px' : null)};
    display: flex;
    @media (min-width: 401px) {
      padding: ${({ shrink }) => (shrink ? '0.5rem 0.75rem' : null)};
    }

    &:hover {
      background-color: transparent;
    }
  }

  .percorso {
    height: 100%;
    align-items: center;
    cursor: auto;
    flex-shrink: ${({ shrink }) => (shrink ? '1' : null)};
    height: 100%;
    img {
      max-height: ${({ shrink }) => (shrink ? '100%' : '40px')};
      @media (min-width: 769px) {
        max-height: 52px;
      }
    }
  }
`;

const NavPercorsi = () => {
  const [shrinkSize, setShrink] = useState(false);
  const onScroll = useCallback(() => {
    setShrink(window.pageYOffset > 50);
  }, []);
  useEventListener('scroll', onScroll, window);
  return (
    <StyledNavbar fixed="top" shrink={shrinkSize}>
      <BulmaNavbar.Brand>
        <BulmaNavbar.Item>
          <div className="text">
            <strong className="is-family-primary has-text-white">23 NOVEMBRE 2021</strong>
            <p className="is-family-primary has-text-white">#ICityRank2021</p>
          </div>
        </BulmaNavbar.Item>
      </BulmaNavbar.Brand>
      <BulmaNavbar.Menu>
        <BulmaNavbar.Container align="right">
          <BulmaNavbar.Item className="percorso">
            <img alt="logo forumpa 2020" src={Percorso1} />
          </BulmaNavbar.Item>
        </BulmaNavbar.Container>
      </BulmaNavbar.Menu>
    </StyledNavbar>
  );
};

export default NavPercorsi;
