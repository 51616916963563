import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  id,
  children,
  className,
  ...props
}) => (
  <>
    <input id={id} type="checkbox" className={`is-checkradio has-background-color ${className}`} {...props} />
    <label htmlFor={id}>{children}</label>
  </>
);

export default Checkbox;

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Checkbox.defaultProps = { className: '' };
