import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as BulmaButton } from 'react-bulma-components';

const Button = ({ className, ...props }) => (
  <BulmaButton className={`has-text-weight-semibold ${className}`} {...props} />
);

Button.propTypes = { className: PropTypes.string };
Button.defaultProps = { className: null };

export default styled(Button)`
  font-size: ${({ theme }) => theme.sizes.medium};
  &.is-info {
    border-color: #FFFF52;
  }
  &.is-primary-gradient {
    background-image: ${({ theme }) => `linear-gradient(to right, ${theme.colors.gradient2}, ${theme.colors.gradient1} )`};
    color: ${({ theme }) => theme.colors.white};
  }
`;
