import React from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Heading, Form } from 'react-bulma-components';
import Button from '../components/button';
import Anagrafica from '../components/Form/anagrafica';
import InfoLavoro from '../components/Form/infoLavoro';
import LocalitaContatti from '../components/Form/localitaContatti';
import DatiAccesso from '../components/Form/datiAccesso';
import Consensi from '../components/Form/consensi';
import GradientHeader from '../gradientHeader';

const {
  Field,
  Control,
} = Form;

const phoneRegex = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;
const validationSchema = Yup.object({
  fpanet_nome: Yup.string().required('Campo richiesto.'),
  fpanet_cognome: Yup.string().required('Campo richiesto.'),
  fpanet_email: Yup.string().email('Email non valida.').required('Campo richiesto.'),
  fpanet_password: Yup.string().min(8, 'Il campo deve avere almeno 8 caratteri.').required('Campo richiesto.'),
  fpanetConfermaPassword: Yup.string().required('Campo richiesto').equals([Yup.ref('fpanet_password'), null], 'Le password devono essere uguali.'),
  fpanet_paese: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Il campo non può contenere numeri.'),
  fpanet_comune_name: Yup.string().when('fpanet_paese', {
    is: (val) => val && val.toLowerCase() === 'italia',
    then: Yup.string().required('Campo richiesto.'),
  }),
  fpanet_phone: Yup.string().matches(phoneRegex, 'Numero non valido.'),
  fpanet_lavoro: Yup.string().test('notEmpty', 'Campo richiesto.', (value) => value !== '0'),
  fpanet_tipologia: Yup.string().when('fpanet_settore', {
    is: '1',
    then: Yup.string().test('notEmpty', 'Campo richiesto.', (value) => value !== '0'),
  }),
  fpanet_settore: Yup.string().when('fpanet_lavoro', {
    is: '1',
    then: Yup.string().test('notEmpty', 'Campo richiesto.', (value) => value !== '0'),
  }),
  fpanet_area: Yup.string().when('fpanet_settore', {
    is: (val) => val === '1' || val === '2',
    then: Yup.string().test('notEmpty', 'Campo richiesto.', (value) => value !== '0'),
  }),
  fpanet_qualifica: Yup.string().when('fpanet_settore', {
    is: (val) => val !== '0',
    then: Yup.string().test('notEmpty', 'Campo richiesto.', (value) => value !== '0'),
  }),
  fpanet_unita1: Yup.string().when('fpanet_lavoro', {
    is: '1',
    then: Yup.string().required('Campo richiesto'),
  }),
});

const signUpToProfilo = async ({ fpanetConfermaPassword, ...values }, switchView) => {
  try {
    await API.post('appDirettaSignUpAPI', '/sign-up', {
      body: {
        ...values,
      },
    });
    localStorage.setItem('confirmationEmail', values.fpanet_email);
    switchView('confirmUser');
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    }
  }
};

const SignUp = ({ switchView }) => {
  const formik = useFormik({
    initialValues: {
      fpanet_area: '0',
      fpanet_unita1: '',
      fpanet_comune_name: '',
      fpanet_cognome: '',
      fpanet_nome: '',
      fpanet_lavoro: '0',
      fpanet_paese: '',
      fpanet_password: '',
      fpanetConfermaPassword: '',
      fpanet_phone: '',
      fpanet_qualifica: '0',
      fpanet_settore: '0',
      fpanet_tipologia: '0',
      fpanet_email: '',
      mktg_newsletter_c: false,
      mktg_terzi_c: false,
      mktg_profilato_c: false,
    },
    validationSchema,
    onSubmit: (values) => {
      signUpToProfilo(values, switchView)
        .catch((errors) => {
          if (errors) {
            switch (errors.message) {
              case 'user_exists':
                formik.setFieldError('fpanet_email', 'Esiste già un utente con questa email.');
                break;
              default:
                formik.setFieldError('fpanet_email', 'Si è verificato un errore durante la registrazione, riprova più tardi.');
                break;
            }
          }
        });
      formik.setSubmitting(false);
    },
  });
  return (
    <div>
      <GradientHeader className="is-flex" style={{ justifyContent: 'flex-start' }}>
        <Heading
          renderAs="p"
        >
          Crea un account
        </Heading>
      </GradientHeader>
      <Box className="is-radiusless">
        <form onSubmit={formik.handleSubmit}>
          <Anagrafica formik={formik} />
          <DatiAccesso formik={formik} />
          <LocalitaContatti formik={formik} />
          <InfoLavoro formik={formik} />
          <Consensi formik={formik} />
          <Field kind="group" align="right">
            <Control>
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  switchView('signIn');
                }}
              >
                ANNULLA
              </Button>
            </Control>
            <Control>
              <Button type="submit" className="is-primary-gradient">REGISTRATI</Button>
            </Control>
          </Field>
        </form>
      </Box>
    </div>
  );
};

SignUp.propTypes = {
  switchView: PropTypes.func.isRequired,
};

export default SignUp;
