import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import Checkbox from './checkbox';
import memoizeFormikForms from '../../helpers/memoForms';

const { Field, Control } = Form;

const fields = [
  'mktg_newsletter_c',
  'mktg_terzi_c',
  'mktg_profilato_c',
];

const StyledCheckbox = styled(Checkbox)`
  input {
    margin-right: 8px;
  }

  &.is-checkradio[type="checkbox"] + label:before {
    border: ${({ theme }) => `2px solid ${theme.colors.greyDark}`}
  }

  &.is-checkradio[type="checkbox"]:hover:not([disabled]) + label:before,
  &.is-checkradio[type="checkbox"]:checked + label:before,
  &.is-checkradio[type="checkbox"]:checked + label:after {
    border-color: ${({ theme }) => `${theme.colors.gradient2} !important`}
  }
`;

const Consensi = ({ formik }) => (
  <>
    <Field>
      <Control>
        <StyledCheckbox
          id="mktg_newsletter_c"
          name="mktg_newsletter_c"
          {...formik.getFieldProps('mktg_newsletter_c')}
          value="true"
        >
          <span>
            Consente all’invio - con modalità automatizzate di contatto
            (e-mail) - della Newsletter editoriale di FPA.
          </span>
        </StyledCheckbox>
      </Control>
    </Field>
    <Field>
      <Control>
        <StyledCheckbox
          id="mktg_terzi_c"
          name="mktg_terzi_c"
          {...formik.getFieldProps('mktg_terzi_c')}
          value="true"
        >
          <span>
            Consente all’invio di comunicazioni promozionali inerenti
            i prodotti e servizi di soggetti terzi rispetto alle Contitolari
            che appartengono al ramo manifatturiero, di servizi (in particolare ICT) e di commercio,
            con modalità di contatto automatizzate e tradizionali da parte dei terzi medesimi,
            a cui vengono comunicati i dati.
          </span>
          <p style={{ marginTop: '1rem' }}>
            L’elenco dei partner delle Contitolari,
            {' '}
            a titolo esemplificativo e non esaustivo, è disponibile
            {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://convegni2021.eventifpa.it/it/partner/partner-detail/?pid=2378">qui</a>
          </p>
        </StyledCheckbox>
      </Control>
    </Field>
    <Field>
      <Control>
        <StyledCheckbox
          id="mktg_profilato_c"
          name="mktg_profilato_c"
          {...formik.getFieldProps('mktg_profilato_c')}
          value="true"
        >
          <span>
            Il/La sottoscritto/a, letta l’informativa resa ai sensi degli articoli 13-14
            del regolamento europeo 679/2016,
            consente alla profilazione anche mediante l’incrocio con altri dati personali raccolti
            con gli strumenti indicati nell’informativa.
          </span>
        </StyledCheckbox>
      </Control>
    </Field>
  </>
);

Consensi.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.string),
    getFieldProps: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
  }).isRequired,
};

const memoizedFunction = memoizeFormikForms(fields);

export default React.memo(Consensi, memoizedFunction);
