import React from 'react';
import PropTypes from 'prop-types';

const CookieBarButton = ({ outlined, ...props }) => {
  const classes = outlined ? 'cookie-bar__button cookie-bar__button--outlined'
    : 'cookie-bar__button';
  return (
    <div className={classes} {...props} />
  );
};

export default CookieBarButton;

CookieBarButton.propTypes = {
  outlined: PropTypes.bool,
};

CookieBarButton.defaultProps = {
  outlined: false,
};
