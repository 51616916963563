import React from 'react';
import Button from '../components/button';
import oauthConfig from '../oauth-exports';
import awsconfig from '../aws-exports';

const uriParams = {
  identity_provider: process.env.REACT_APP_IDP,
  redirect_uri: oauthConfig.redirectSignIn,
  response_type: oauthConfig.responseType,
  client_id: awsconfig.aws_user_pools_web_client_id,
  scope: encodeURIComponent(oauthConfig.scope.join(' ')),
};

const queryString = Object.keys(uriParams)
  .map((key) => `${key}=${uriParams[key]}`)
  .join('&');

const OAuthUrl = `https://${oauthConfig.domain}/oauth2/authorize?${queryString}`;

const signIn = () => {
  window.location.href = OAuthUrl;
};

const OauthButton = () => (
  <Button className="is-radiusless is-primary-gradient" onClick={signIn}>ACCEDI</Button>
);

export default OauthButton;
