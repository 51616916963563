import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Navbar as BulmaNavbar } from 'react-bulma-components';
import UserContext from '../Auth/userContext';
import ProfileMenu from './profileMenu';
import logo from '../assets/images/logo.png';
import NavPercorsi from './navPercorsi';
import Sidebar from './sideBar';

const StyledNavbar = styled(({ zIndex, ...props }) => (<BulmaNavbar {...props} />))`
  background-color: ${({ theme }) => theme.colors.primary};
  border-image-slice: 1;
  border-style: solid;
  border-width: 0 0 4px;
  border-image-source: ${({ theme }) => `linear-gradient(to right, ${theme.colors.gradient1}, ${theme.colors.gradient2} )`};
  min-height: 98px;

  &.is-fixed-top {
    z-index: ${({ zIndex }) => (zIndex || null)};
  }

  .navbar-burger {
    align-self: center;
    span {
      height: 2px;
    }
  }

  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    background-color: rgba(250,250,250,0.2);
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
    @media (max-width: 360px) {
      flex: 0;
    }
    .navbar-link {
      align-items: center;
      @media (max-width: 360px) {
      }
    }

    @media(max-width: 360px) {
      .navbar-link:not(.is-arrowless)::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 25px;
        width: 1px;
        height: 30px;
        background-color: white;
        margin-top: -15px;
      }

    }
    .navbar-link:not(.is-arrowless)::after {
      border-color: ${({ theme }) => theme.colors.white};
    }
  }

  .navbar-item.has-dropdown:focus .navbar-link,
  .navbar-item.has-dropdown:hover .navbar-link,
  .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: rgba(250,250,250,0.2);
  }

  .navbar-dropdown {
    background-color: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top: 2px solid #dbdbdb;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    display: none;
    font-size: 0.875rem;
    left: auto;
    right: 0;
    min-width: calc(100% - 30px);
    @media(min-width: 360px) {
      min-width: 100%;
    }
    position: absolute;
    top: 100%;
    z-index: 20;
  }

  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar-item img {
    max-height: 64px;
    width: auto;
    height: 100%;
  }

  .brand-grid {
    display: grid;
    grid-template-columns: auto;
    @media (min-width: 769px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  img.event__logo {
    height: 60px;
  }

  .navbar__box-diretta {
    border-radius: 4px;
    line-height: 100%;
    padding: ${({ theme }) => `${theme.sizes.small}`};
    background-color: ${({ theme }) => `${theme.colors.lightBlue}`};

    & > span {
      line-height: 100%;
    }
  }

  &.navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: center;
    display: flex;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  &.is-fixed-top .navbar-menu {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: visible;
  }

  .circle {
    background-color: ${({ theme }) => theme.colors.gradient2};
    border-radius: 50%;
    height: 10px;
    margin-left: ${({ theme }) => theme.sizes.small};
    top: 0;
    @media (min-width 601px) {
      top: -1px;
    }
    width: 10px;
  }
`;

const renderRoleButton = (isRelator, isModerator) => {
  if (!isRelator && !isModerator) {
    return null;
  }
  return (
    <BulmaNavbar.Item
      className="has-text-white has-text-weight-bold is-hidden-touch"
      renderAs="a"
      href={isRelator ? '/relatore' : '/moderatore'}
    >
      {isRelator ? 'PANNELLO RELATORE' : 'PANNELLO MODERATORE'}
    </BulmaNavbar.Item>
  );
};

const Navbar = () => {
  const { location } = window;
  const { user, userGroups } = useContext(UserContext);
  const [isRelator, setIsRelator] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [sidebarOpen, setSidebar] = useState(false);
  useEffect(() => {
    setIsRelator(userGroups.includes('relatore'));
    setIsModerator(userGroups.includes('moderatore'));
  }, [userGroups]);
  const toggleSidebar = () => setSidebar(!sidebarOpen);
  return (
    <>
      <Sidebar
        open={sidebarOpen}
        toggleSidebar={toggleSidebar}
        isRelator={isRelator}
        isModerator={isModerator}
      />
      <StyledNavbar
        fixed="top"
        zIndex={31}
      >
        <BulmaNavbar.Brand>
          <BulmaNavbar.Burger className={`has-text-white ${sidebarOpen ? 'is-active' : null}`} onClick={toggleSidebar} />
          <BulmaNavbar.Item renderAs="a" href="/">
            <div className="brand-grid" style={{ gridTemplateRows: '1fr' }}>
              <picture>
                <source srcSet={logo} type="image/png" />
                <img className="event__logo" src={logo} alt="ForumPa Logo" />
              </picture>
            </div>
          </BulmaNavbar.Item>
        </BulmaNavbar.Brand>
        <BulmaNavbar.Menu>
          <BulmaNavbar.Container align="right">
            { user != null && user.username != null && (
              <>
                {/* <BulmaNavbar.Item
                  className="has-text-white has-text-weight-bold is-hidden-touch"
                  href="/partners"
                >
                  SPAZIO ESPOSITIVO
                </BulmaNavbar.Item> */}
                {renderRoleButton(isRelator, isModerator)}
                <ProfileMenu />
              </>
            )}
          </BulmaNavbar.Container>
        </BulmaNavbar.Menu>
      </StyledNavbar>
      { location.pathname === '/' && <NavPercorsi /> }
    </>
  );
};

export default Navbar;
