import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Heading, Columns } from 'react-bulma-components';
import OauthButton from './OauthButton';
import GradientHeader from '../gradientHeader';
import Button from '../components/button';

const Text = styled.p`
  color: ${({ theme }) => theme.colors.textGrey};
  margin-top: ${({ theme }) => theme.sizes.medium};
`;

const Divider = styled.div`
  background-color: #fafafa;
  width: calc(100% + 16px);
  height: 2px;
  margin: 0;
  margin-left: -8px;
  &:before,
  &:after {
    content: none;
  }
  @media (min-width: 769px){
    width: 2px;
    margin-left: 0;
    height: calc(100% + 16px);
    margin-top: -8px;
  }
`;

const SignIn = ({ switchView }) => (
  <div>
    <GradientHeader className="is-flex is-vcentered">
      <Heading
        renderAs="p"
      >
        Benvenuto! Accedi alla diretta
      </Heading>
    </GradientHeader>
    <Box className="is-radiusless is-marginless has-background-white-ter">
      <Columns>
        <Columns.Column className="has-text-centered">
          <Heading size={5}>Hai già un account?</Heading>
          <OauthButton />
          <Text>
            Se hai già creato un account su profilo.forumpa,
            clicca su ACCEDI ed inserisci le credenziali
          </Text>
        </Columns.Column>
        <Columns.Column narrow paddingless className="is-hidden-mobile">
          <Divider className="is-divider-vertical" />
        </Columns.Column>
        <Columns.Column narrow paddingless className="is-hidden-tablet">
          <Divider className="divider" />
        </Columns.Column>
        <Columns.Column className="has-text-centered">
          <Heading size={5}>Non sei ancora registrato?</Heading>
          <Button className="is-radiusless is-primary-gradient" onClick={() => switchView('signUp')}>REGISTRATI ADESSO</Button>
          <Text>
            Crea un account adesso per accedere alla diretta
          </Text>
        </Columns.Column>
      </Columns>
    </Box>
    <GradientHeader disableWhiteOnMobile className="is-flex is-vcentered">
      {/* <p className="has-text-white">
        INCONTRA I PARTNER DELLA MANIFESTAZIONE E ACCEDI ALLO
        {' '}
        <a
          className="has-text-weight-semibold"
          style={{ color: '#92C01F' }}
          href="/partners"
        >
          SPAZIO ESPOSITIVO
        </a>
      </p> */}
    </GradientHeader>
  </div>
);

export default SignIn;

SignIn.propTypes = {
  switchView: PropTypes.func.isRequired,
};
