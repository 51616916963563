import styled from 'styled-components';

const GradientHeader = styled.div`
  align-items: center;
  background-color: ${({ disableWhiteOnMobile, theme }) => (disableWhiteOnMobile ? theme.colors.primary : theme.colors.white)};
  .title {
    color: ${({ theme }) => theme.colors.primary};
  }
  height: 70px;
  justify-content: center;
  padding: 1rem;
  .title {
    font-size: 1.125rem;
    @media (min-width: 1024px) {
      font-size: ${({ theme }) => theme.sizes.large};
    }
  }
  @media (min-width: 1024px) {
    background-color: ${({ theme }) => theme.colors.primary};
    .title {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export default GradientHeader;
