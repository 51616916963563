import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';

const {
  Control,
  Field,
  Label,
  Select: BulmaSelect,
  Help,
} = Form;

/**
 * TODO i 5px mi servono per allineare la select rispetto ai campi sopra,
 * bisogna capire da dove esce fuori
 */
const StyledField = styled(Field)`
  @media(min-width: 769px) {
    flex-basis: calc(50% - 5px);
`;

const StyledSelect = styled(BulmaSelect)`
  &.select:not(.is-multiple):not(.is-loading):after {
    border-color: ${({ theme }) => theme.colors.primary}
  }
  &.select:not(.is-multiple):not(.is-loading):hover:after {
    border-color: #363636;
  }
  &.select select {
    border-radius: 0;
  }
`;

const Select = ({
  error,
  errorText,
  fullWidth,
  half,
  horizontal,
  innerLabel,
  label,
  name,
  narrow,
  noFieldWrap,
  options,
  ...props
}) => {
  const field = (
    <>
      {!innerLabel && (<Label className="is-family-primary">{ label }</Label>)}
      <Control>
        {innerLabel && (<Label className="is-family-primary">{ label }</Label>)}
        <StyledSelect {...props} className={fullWidth ? 'is-fullwidth' : null}>
          { options.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          )) }
        </StyledSelect>
      </Control>
      {error ? (<Help color="danger">{errorText}</Help>) : null}
    </>
  );
  if (narrow) {
    return (<Field className="is-narrow">{field}</Field>);
  }
  return noFieldWrap ? field : (<StyledField half={half ? 1 : 0} className={half ? 'is-narrow' : null}>{field}</StyledField>);
};

export default Select;

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  innerLabel: PropTypes.bool,
  noFieldWrap: PropTypes.bool,
  half: PropTypes.bool,
  narrow: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  horizontal: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

Select.defaultProps = {
  error: '',
  errorText: '',
  horizontal: false,
  innerLabel: false,
  noFieldWrap: false,
  half: false,
  narrow: false,
  fullWidth: false,
};
