import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import memoizeFormikForms from '../../helpers/memoForms';
import Input from './input';

const { Field } = Form;

const fields = [
  'fpanet_paese',
  'fpanet_comune_name',
  'fpanet_phone',
];

const LocalitaContatti = ({ formik }) => (
  <>
    <Field horizontal>
      <div className="field-body">
        <Input
          id="fpanet_paese"
          name="fpanet_paese"
          label="Paese"
          placeholder="Paese"
          half
          error={formik.touched.fpanet_paese && formik.errors.fpanet_paese}
          errorText={formik.errors.fpanet_paese}
          {...formik.getFieldProps('fpanet_paese')}
        />
        {formik.values.fpanet_paese.toLowerCase() === 'italia' && (
          <Input
            id="fpanet_comune_name"
            name="fpanet_comune_name"
            label="Comune"
            placeholder="Comune"
            error={formik.touched.fpanet_comune_name && formik.errors.fpanet_comune_name}
            errorText={formik.errors.fpanet_comune_name}
            {...formik.getFieldProps('fpanet_comune_name')}
          />
        )}
      </div>
    </Field>
    <Field horizontal>
      <div className="field-body">
        <Input
          id="fpanet_phone"
          name="fpanet_phone"
          label="Telefono"
          half
          placeholder="Telefono"
          error={formik.touched.fpanet_phone && formik.errors.fpanet_phone}
          errorText={formik.errors.fpanet_phone}
          {...formik.getFieldProps('fpanet_phone')}
        />
      </div>
    </Field>
  </>
);

const memoizedFunction = memoizeFormikForms(fields);

export default React.memo(LocalitaContatti, memoizedFunction);

LocalitaContatti.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.object,
    getFieldProps: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
    values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  }).isRequired,
};
