import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import memoizeFormikForms from '../../helpers/memoForms';
import tipologiaOptions from './tipologiaOptions.json';
import qualificaPubblicaOptions from './qualificaPubblicaOptions.json';
import qualificaPrivataOptions from './qualificaPrivataOptions.json';
import qualificaTerzoSettoreOptions from './qualificaTerzoSettoreOptions.json';
import areaPubblicaOptions from './areaPubblicaOptions.json';
import areaPrivataOptions from './areaPrivataOptions.json';
import Input from './input';
import Select from './select';

const { Field } = Form;

const fields = [
  'fpanet_lavoro',
  'fpanet_settore',
  'fpanet_tipologia',
  'fpanet_qualifica',
  'fpanet_area',
  'fpanet_unita1',
];

const jobOptions = [
  { label: '-', value: 0 },
  { label: 'occupato', value: 1 },
  { label: 'studente', value: 2 },
  { label: 'non occupato/altro', value: 3 },
];

const settoreOptions = [
  { label: '-', value: 0 },
  { label: 'pubblico', value: 1 },
  { label: 'privato', value: 2 },
  { label: 'terzo settore', value: 3 },
];

const getQualificaOptions = (settore) => {
  switch (settore) {
    case '1':
      return qualificaPubblicaOptions;
    case '2':
      return qualificaPrivataOptions;
    case '3':
      return qualificaTerzoSettoreOptions;
    default:
      return [{ label: '-', value: 0 }];
  }
};

const getAreaOptions = (settore) => {
  switch (settore) {
    case '1':
      return areaPubblicaOptions;
    case '2':
      return areaPrivataOptions;
    default:
      return [{ label: '-', value: 0 }];
  }
};

const InfoLavoro = ({ formik }) => {
  const [
    optionsQualifica,
    setQualifica,
  ] = useState(getQualificaOptions(formik.values.fpanet_settore));
  const [optionsArea, setArea] = useState(getAreaOptions(formik.values.fpanet_settore));
  const resetFields = useCallback((resetSettore) => {
    formik.setFieldValue('fpanet_tipologia', '0');
    formik.setFieldValue('fpanet_area', '0');
    formik.setFieldValue('fpanet_qualifica', '0');
    formik.setFieldTouched('fpanet_tipologia', false);
    formik.setFieldTouched('fpanet_area', false);
    formik.setFieldTouched('fpanet_qualifica', false);
    formik.setFieldTouched('fpanet_unita1', false);
    if (resetSettore) {
      formik.setFieldValue('fpanet_settore', '0');
    }
  }, [formik]);
  useEffect(() => {
    setQualifica(getQualificaOptions(formik.values.fpanet_settore));
    setArea(getAreaOptions(formik.values.fpanet_settore));
  }, [formik.values.fpanet_settore]);
  return (
    <>
      <Field horizontal>
        <div className="field-body">
          <Select
            id="fpanet_lavoro"
            name="fpanet_lavoro"
            label="Lavoro"
            options={jobOptions}
            half={formik.values.fpanet_lavoro !== '1'}
            {...formik.getFieldProps('fpanet_lavoro')}
            fullWidth
            error={formik.touched.fpanet_lavoro && formik.errors.fpanet_lavoro}
            errorText={formik.errors.fpanet_lavoro}
            onChange={(e) => {
              formik.handleChange(e);
              resetFields(true);
            }}
          />
          {formik.values.fpanet_lavoro === '1'
            && (
              <Select
                id="fpanet_settore"
                name="fpanet_settore"
                label="Settore"
                options={settoreOptions}
                fullWidth
                error={formik.touched.fpanet_settore && formik.errors.fpanet_settore}
                errorText={formik.errors.fpanet_settore}
                {...formik.getFieldProps('fpanet_settore')}
                onChange={(e) => {
                  formik.handleChange(e);
                  resetFields();
                }}
              />
            )}
        </div>
      </Field>
      <Field horizontal>
        <div className="field-body">
          {formik.values.fpanet_settore === '1'
            && (
              <Select
                id="fpanet_tipologia"
                name="fpanet_tipologia"
                label="Tipologia"
                options={tipologiaOptions}
                fullWidth
                error={formik.touched.fpanet_tipologia && formik.errors.fpanet_tipologia}
                errorText={formik.errors.fpanet_tipologia}
                {...formik.getFieldProps('fpanet_tipologia')}
              />
            )}
          {formik.values.fpanet_settore !== '0'
            && (
              <Select
                id="fpanet_qualifica"
                name="fpanet_qualifica"
                label="Qualifica"
                fullWidth
                error={formik.touched.fpanet_qualifica && formik.errors.fpanet_qualifica}
                errorText={formik.errors.fpanet_qualifica}
                half={formik.values.fpanet_settore !== '1'}
                options={optionsQualifica}
                {...formik.getFieldProps('fpanet_qualifica')}
              />
            )}
        </div>
      </Field>
      <Field horizontal>
        <div className="field-body">
          {['1', '2'].includes(formik.values.fpanet_settore)
            && (
              <Select
                id="fpanet_area"
                name="fpanet_area"
                label="Area"
                half
                fullWidth
                options={optionsArea}
                error={formik.touched.fpanet_area && formik.errors.fpanet_area}
                errorText={formik.errors.fpanet_area}
                {...formik.getFieldProps('fpanet_area')}
              />
            )}
          {formik.values.fpanet_lavoro === '1' && (
            <Input
              id="fpanet_unita1"
              name="fpanet_unita1"
              label="Ente/Azienda"
              half
              placeholder="Ente/Azienda"
              error={formik.touched.fpanet_unita1 && formik.errors.fpanet_unita1}
              errorText={formik.errors.fpanet_unita1}
              {...formik.getFieldProps('fpanet_unita1')}
            />
          )}
        </div>
      </Field>
    </>
  );
};

InfoLavoro.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.object,
    getFieldProps: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    handleChange: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
    values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  }).isRequired,
};

const memoizedFunction = memoizeFormikForms(fields);

export default React.memo(InfoLavoro, memoizedFunction);
