const memoizeFormikForms = (fields) => ({ formik: prevFormik }, { formik: nextFormik }) => {
  const sameTouched = fields.reduce((carry, field) => (
    carry && prevFormik.touched[field] === nextFormik.touched[field]
  ), true);
  const sameErrors = fields.reduce((carry, field) => (
    carry && prevFormik.errors[field] === nextFormik.errors[field]
  ), true);
  const sameValues = fields.reduce((carry, field) => (
    carry && prevFormik.values[field] === nextFormik.values[field]
  ), true);
  return sameTouched && sameErrors && sameValues;
};

export default memoizeFormikForms;
