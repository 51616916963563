import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './sass/app.scss';
import AppConfig from './AppConfig';
import App from './App';
import * as serviceWorker from './serviceWorker';

AppConfig.initConfig();

Sentry.init({
  dsn: 'https://9e3cca9e20de41048d1c6e29ef2368d8@o234395.ingest.sentry.io/5301697',
  environment: process.env.REACT_APP_SENTRY_ENV,
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
