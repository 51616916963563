import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Columns, Container } from 'react-bulma-components';
import CookieBar from './Coookiebar/cookieBar';
import ButtonLink from './buttonLink';
import fpaLogo from '../assets/images/fpa_footer.svg';
import fpaLogowebp from '../assets/images/fpa_footer.webp';
import d360Logo from '../assets/images/d360_footer.svg';
import d360Logowebp from '../assets/images/d360_footer.webp';

const OrderedColumn = styled(Columns.Column)`
  @media (max-width: 768px) {
    order: ${({ order }) => order || null};
  }

  .link-section > * {
    color: #90CAF9;
  }
`;

const CompanyFooter = ({ className }) => (
  <>
    <Container className={`has-background-black-bis is-fluid ${className}`}>
      <Columns className="is-vcentered is-mobile">
        <Columns.Column mobile={{ size: 6 }} narrow>
          <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.forumpa.it/">
            <picture>
              <source srcSet={fpaLogowebp} type="image/webp" />
              <source srcSet={fpaLogo} type="image/svg+xml" />
              <img src={fpaLogo} alt="Logo ForumPA" />
            </picture>
          </a>
        </Columns.Column>
        <OrderedColumn order={1} mobile={{ size: 12 }} className="has-text-centered">
          <p className="has-text-white is-size-7-touch is-size-6-desktop">
            FPA S.r.l. – Via Ostiense, 92, Roma - Italia CAP 00154
            – tel. 06/684251 – fax 06/68425433
          </p>
          <p className="has-text-white is-size-7-touch is-size-6-desktop">
            Codice Fiscale/Partita IVA n. 10693191008 - R.E.A. Roma n. 1249791 -
            <a style={{ color: '#90CAF9' }} className="has-text-weight-semibold" href="mailto:info@forumpa.it"> info@forumpa.it</a>
          </p>
          <p className="link-section has-text-white has-text-weight-semibold is-size-7-touch is-size-6-desktop">
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.networkdigital360.it/privacy">Privacy Policy</a>
            {' - '}
            <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.networkdigital360.it/cookie">Cookie Policy</a>
            {' - '}
            <ButtonLink id="cookie-center" className="has-text-weight-semibold is-family-secondary is-size-7-touch is-size-6-desktop">Cookie Center</ButtonLink>
          </p>
        </OrderedColumn>
        <Columns.Column className="has-text-right" mobile={{ size: 6 }} narrow>
          <a target="_blank" rel="noopener noreferrer nofollow" href="https://www.digital360.it/">
            <picture>
              <source srcSet={d360Logowebp} type="image/webp" />
              <source srcSet={d360Logo} type="image/svg+xml" />
              <img src={d360Logo} alt="Logo ForumPA" />
            </picture>
          </a>
        </Columns.Column>
      </Columns>
    </Container>
    <CookieBar />
  </>
);

export default CompanyFooter;

CompanyFooter.propTypes = {
  className: PropTypes.string,
};

CompanyFooter.defaultProps = { className: '' };
