import React, { useState } from 'react';
import { Columns, Section, Container } from 'react-bulma-components';
import styled, { ThemeProvider } from 'styled-components';
import Navbar from '../components/navbar';
import SignIn from './signIn';
import SignUp from './signUp';
import Footer from '../components/footer';
import { useScrollToTop } from '../helpers/hooks';
import ConfirmUser from './confirmUser';
import AppTheme from '../themes/theme';
import background from '../assets/images/sfondo_signin.png';

const SectionWithBackground = styled(({ currentView, ...props }) => <Section {...props} />)`
  background: ${() => ` linear-gradient(
      rgba(38, 89, 165, 0.4),
      rgba(38, 89, 165, 0.4)
    ), url(${background}) no-repeat fixed center`};
  background-size: cover;
  @media (max-width: 769px) {
    &.section {
      padding: ${({ currentView }) => (currentView === 'signUp' ? 0 : null)};
    }

    ${({ currentView }) => currentView === 'signUp' && `
      .columns {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
      }
      .columns:last-child {
        margin-bottom: 0
      }

      .column {
        margin: 0;
        padding: 0 !important;
      }
    `}
  }
`;

const loadComponent = (state, props) => {
  switch (state) {
    case 'signIn':
      return (<SignIn {...props} />);
    case 'signUp':
      return (<SignUp {...props} />);
    case 'confirmUser':
      return (<ConfirmUser {...props} />);
    default:
      return (<SignIn {...props} />);
  }
};

// Padding top dato dalla seguente formula:
// (altezzaNavbar - paddingHtml) +  16px
const PaddedContainer = styled(({ currentView, ...props }) => <Container {...props} />)`
  ${({ currentView }) => currentView === 'signUp' && `
    padding-top: calc(98px + 144px - 3.25rem + 16px);
    @media (min-width 769px) {
      padding-top: calc(98px - 3.25rem + 16px);
    }
  `}

  ${({ currentView }) => currentView !== 'signUp' && `
  padding-top: calc(98px + 144px - 3.25rem + 16px);
    @media (min-width 769px) {
      padding-top: calc(98px - 3.25rem + 16px);
    }
  `}
`;

const CustomAuthenticator = () => {
  const [currentView, setView] = useState('signIn');
  useScrollToTop(currentView);
  return (
    <ThemeProvider theme={AppTheme}>
      <Navbar />
      <SectionWithBackground currentView={currentView} size="medium">
        <PaddedContainer currentView={currentView}>
          <Columns className="is-vcentered">
            <Columns.Column
              size={8}
              offset={2}
            >
              { loadComponent(currentView, { switchView: setView }) }
            </Columns.Column>
          </Columns>
        </PaddedContainer>
      </SectionWithBackground>
      <Footer />
    </ThemeProvider>
  );
};

export default CustomAuthenticator;
