const theme = {
  colors: {
    primary: '#052054',
    gradient1: '#42B2AC',
    gradient2: '#92C01F',
    secondary: '#FCD601',
    lightBlue: '#2659A5',
    white: '#ffffff',
    whiteTer: '#eeeeee',
    text: '#363636',
    textGrey: '#9e9e9e',
    greyDark: '#424242',
    greyLight: '#e0e0e0',
    greyTab: '#f3f3f3',
    approved: '#92C01F',
    question: '#2659A5',
    rejected: '#D54A48',
    waiting: '#424242',
  },
  roomColors: {
    red: '#EF5350',
    green: '#4CAF50',
    blue: '#2659A5',
  },
  tematiche: {
    fpa: '#89A4D5',
    fpasud: '#F19124',
    fpasanita: '#00ABA8',
  },
  grid: {
    gap: '0.75rem',
  },
  sizes: {
    small: '0.5rem',
    medium: '1rem',
    mediumLarge: '1.125rem',
    large: '1.5rem',
    xlarge: '2rem',
  },
};

export default theme;
