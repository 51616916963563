import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import Input from './input';
import memoizeFormikForms from '../../helpers/memoForms';

const { Field } = Form;

const fields = [
  'fpanet_nome',
  'fpanet_cognome',
];

const Anagrafica = ({ formik }) => (
  <Field horizontal>
    <div className="field-body">
      <Input
        id="fpanet_nome"
        name="fpanet_nome"
        label="Nome"
        placeholder="Nome"
        error={formik.touched.fpanet_nome && formik.errors.fpanet_nome}
        errorText={formik.errors.fpanet_nome}
        {...formik.getFieldProps('fpanet_nome')}
      />
      <Input
        id="fpanet_cognome"
        name="fpanet_cognome"
        label="Cognome"
        placeholder="Cognome"
        error={formik.touched.fpanet_cognome && formik.errors.fpanet_cognome}
        errorText={formik.errors.fpanet_cognome}
        {...formik.getFieldProps('fpanet_cognome')}
      />
    </div>
  </Field>
);

Anagrafica.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.object,
    getFieldProps: PropTypes.func,
    touched: PropTypes.objectOf(PropTypes.bool),
  }).isRequired,
};

const memoizedFunction = memoizeFormikForms(fields);

export default React.memo(Anagrafica, memoizedFunction);
