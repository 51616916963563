import React, { useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Form, Notification as BulmaNotification } from 'react-bulma-components';
import Input from '../components/Form/input';
import Button from '../components/button';
import ButtonLink from '../components/buttonLink';

const {
  Field,
  Control,
} = Form;

const validationSchema = Yup.object({
  confirmCode: Yup.string().required('Campo Richiesto.'),
});

const confirmSignup = async ({ confirmCode }, switchView) => {
  const currentEmail = localStorage.getItem('confirmationEmail');
  try {
    await Auth.confirmSignUp(currentEmail, confirmCode);
    localStorage.removeItem('confirmationEmail');
    switchView('signIn');
  } catch (error) {
    console.error(error);
    switch (error.code) {
      case 'CodeMismatchException':
        return 'Codice non valido.';
      default:
        return 'Errore durante il processo di verifica codice.';
    }
  }
  return false;
};

const resendConfirmCode = async () => {
  const currentEmail = localStorage.getItem('confirmationEmail');
  if (!currentEmail) {
    return false;
  }
  try {
    await Auth.resendSignUp(currentEmail);
    return true;
  } catch (error) {
    console.error(error);
  }
  return false;
};

const ResponsiveField = styled(Field)`
  &.is-grouped {
    display: block;
  }
  @media (min-width: 400px) {
    &.is-grouped {
      display: flex;
    }
  }
`;

const StyledNotification = styled(BulmaNotification)`
  position: fixed;
  bottom: 16px;
  left: 8px;
  width: calc(100% - 16px);
  z-index: 1;
  @media (min-width: 769px) {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Notification = ({ text }) => (
  <StyledNotification className="is-fixed" color="primary">
    { text }
  </StyledNotification>
);

Notification.propTypes = {
  text: PropTypes.string.isRequired,
};

const ConfirmUser = ({ switchView }) => {
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [notificationText, setNotificationText] = useState('');
  const formik = useFormik({
    initialValues: {
      confirmCode: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const codeError = await confirmSignup(values, switchView);
      if (codeError) {
        formik.setFieldError('confirmCode', codeError);
      }
      formik.setSubmitting(false);
    },
  });
  const resendCode = useCallback(async (event) => {
    event.preventDefault();
    const result = await resendConfirmCode();
    setNotificationOpen(true);
    setNotificationText(result ? 'Codice reinviato' : 'Si è verificato un errore durante il reinvio del codice.');
    setTimeout(() => setNotificationOpen(false), 5000);
  }, []);
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Input
          id="confirmCode"
          name="confirmCode"
          label="Codice Conferma"
          placeholder="Codice Conferma"
          error={formik.touched.confirmCode && formik.errors.confirmCode}
          errorText={formik.errors.confirmCode}
          {...formik.getFieldProps('confirmCode')}
        />
        <ResponsiveField kind="group" align="right">
          <Control style={{ marginRight: 'auto' }}>
            <ButtonLink onClick={resendCode} style={{ fontSize: '16px', lineHeight: '40px' }}>Reinvia codice conferma</ButtonLink>
          </Control>
          <Control>
            <Button type="submit" color="info">CONFERMA</Button>
          </Control>
        </ResponsiveField>
      </form>
      { isNotificationOpen && <Notification text={notificationText} />}
    </Box>
  );
};

ConfirmUser.propTypes = {
  switchView: PropTypes.func.isRequired,
};

export default ConfirmUser;
