import React from 'react';
import PropTypes from 'prop-types';

const ButtonLink = ({ className, ...props }) => (
  <button className={`link-button ${className}`} type="button" {...props} />
);

export default ButtonLink;

ButtonLink.propTypes = {
  className: PropTypes.string,
};

ButtonLink.defaultProps = {
  className: '',
};
