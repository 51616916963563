import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bulma-components';
import PartnerArea from './partnerArea';
import CompanyFooter from './companyFooter';

const StyledFooter = styled.footer`
  border-image: ${({ theme }) => `linear-gradient(to right, ${theme.colors.gradient1}, ${theme.colors.gradient2})`};
  border-image-slice: 1;
  border-width: 3px 0 0;
  border-style: solid;

  .container.has-background-black-bis {
    padding-bottom: 64px;
  }
`;

const Footer = () => (
  <StyledFooter id="partner" className="has-background-white">
    <Container className="is-fluid">
      <PartnerArea />
    </Container>
    <CompanyFooter />
  </StyledFooter>
);

export default Footer;
