import React from 'react';
import PropTypes from 'prop-types';

const createSwitch = (required, name, checked, onChange) => {
  if (required) {
    return (
      <span
        className="cookie-bar__options__options-box__item__required"
      >
        Strettamente necessari
      </span>
    );
  }
  return (
    <div className="cookie-bar__switch">
      <label htmlFor={`${name}-checkbox`} className="is-relative">
        <input
          id={`${name}-checkbox`}
          type="checkbox"
          checked={checked}
          onChange={onChange || null}
        />
        <span className="cookie-bar__slider round" />
      </label>
    </div>
  );
};

const CookieOption = ({
  title,
  required,
  name,
  checked,
  onChange,
  children,
}) => (
  <div className="cookie-bar__options__options-box__item">
    <div className="cookie-bar__options__options-box__item__input-container">
      <h4 className="cookiebar-text-bold cookiebar-text-size-2">{title}</h4>
      {createSwitch(required, name, checked, onChange)}
    </div>
    {children}
  </div>
);

export default CookieOption;

CookieOption.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

CookieOption.defaultProps = {
  required: false,
  checked: false,
  onChange: () => {},
};
