import React, { useRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../helpers/hooks';

const Overlay = styled.div`
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 31;
    transition: opacity 0.4s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
`;

const renderRoleItem = (isRelator, isModerator) => {
  if (!isRelator && !isModerator) {
    return null;
  }
  return (
    <li>
      <a href={isRelator ? '/relatore' : '/moderatore'}>
        {isRelator ? 'Pannello Relatore' : 'Pannello Moderatore'}
      </a>
    </li>
  );
};

const SideBar = styled(({
  open,
  toggleSidebar,
  isModerator,
  isRelator,
  ...props
}) => {
  const ref = useRef(null);
  const close = (e) => {
    const burgerClicked = e.target.classList.contains('navbar-burger') || e.target.nodeName === 'SPAN';
    if (!burgerClicked && open) {
      toggleSidebar();
    }
  };
  useOnClickOutside(ref, close);
  return (
    <>
      { open && <Overlay />}
      <div {...props} ref={ref}>
        <ul className="is-family-primary has-text-weight-semibold is-uppercase">
          {/* <li><a href="/partners">Spazio espositivo</a></li> */}
          {renderRoleItem(isRelator, isModerator)}
        </ul>
      </div>
    </>
  );
})`
  background-color: ${({ theme }) => theme.colors.primary};
  height: calc(100vh - 98px);
  position: fixed;
  top: 98px;
  transform: ${({ open }) => (open ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)')};
  transition-timing-function: cubic-bezier(0.7,0,0.3,1);
  transition: transform 0.4s;
  z-index: 33;

  ul li a {
    display: block;
    padding: ${({ theme }) => theme.sizes.medium};
    color: ${({ theme }) => theme.colors.white};

    :hover {
      background-color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
`;

export default SideBar;
