import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from './aws-exports';
import oauth from './oauth-exports';

const initConfig = () => {
  Amplify.configure(awsconfig);
  Auth.configure({ oauth });
  API.configure({
    aws_cloud_logic_custom: [
      ...awsconfig.aws_cloud_logic_custom,
      {
        name: 'KinesisAPI',
        endpoint: 'https://apifpalive.forumpa.it',
      },
    ],
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC,
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  });
};

export default {
  initConfig,
};
