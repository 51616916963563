import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import CookieOptionsHeading from './cookieOptionsHeading';
import CookieOption from './cookieOption';
import Button from './cookieBarButton';

const CookieBarOptions = ({ preferences, onUnmount, closeBar }) => {
  const [switches, setSwitches] = useState(preferences);
  const disableAll = useCallback(() => setSwitches({
    ...switches,
    required: true,
    analytics: false,
    profile: false,
  }), [switches]);

  const enableAll = useCallback(() => setSwitches({
    ...switches,
    required: true,
    analytics: true,
    profile: true,
  }), [switches]);

  const saveAndClose = useCallback(() => {
    onUnmount(switches);
    closeBar();
  }, [switches, onUnmount, closeBar]);
  return (
    <>
      <div className="cookie-bar__logo-container">
        <img
          className="cookie-bar__logo"
          alt="logo networkd360"
          src="https://cdnd360.it/networkdigital360/ND360_Logo_POS.svg"
        />
        <h4>I tuoi contenuti, la tua privacy!</h4>
      </div>
      <CookieOptionsHeading onAccept={enableAll} onRefuse={disableAll} />
      <div className="cookie-bar__options">
        <div className="cookie-bar__options__options-box">
          <CookieOption title="COOKIE TECNICI" required name="required" checked>
            <p>
              I cookie tecnici sono necessari al funzionamento del sito web perché
              abilitano funzioni per facilitare la navigazione dell’utente, che per
              esempio potrà accedere al proprio profilo senza dover eseguire ogni
              volta il login oppure potrà selezionare la lingua con cui desidera
              navigare il sito senza doverla impostare ogni volta.
            </p>
          </CookieOption>
          <CookieOption
            title="COOKIE ANALITICI"
            name="analytics"
            checked={switches.analytics}
            onChange={() => setSwitches({ ...switches, analytics: !switches.analytics })}
          >
            <p>
              I cookie analitici, che possono essere di prima o di terza parte, sono
              installati per collezionare informazioni sull’uso del sito web. In
              particolare, sono utili per analizzare statisticamente gli accessi o
              le visite al sito stesso e per consentire al titolare di migliorarne
              la struttura, le logiche di navigazione e i contenuti.
            </p>
          </CookieOption>
          <CookieOption
            title="COOKIE DI PROFILAZIONE E SOCIAL PLUGIN"
            name="profile"
            checked={switches.profile}
            onChange={() => setSwitches({ ...switches, profile: !switches.profile })}
          >
            <p>
              I cookie di profilazione e i social plugin, che possono essere di
              prima o di terza parte, servono a tracciare la navigazione
              dell’utente, analizzare il suo comportamento ai fini marketing e
              creare profili in merito ai suoi gusti, abitudini, scelte, etc. In
              questo modo è possibile ad esempio trasmettere messaggi pubblicitari
              mirati in relazione agli interessi dell’utente ed in linea con le
              preferenze da questi manifestate nella navigazione online.
            </p>
          </CookieOption>
        </div>
        <div className="cookie-bar__options__footer">
          <Button onClick={saveAndClose}>SALVA ED ESCI</Button>
        </div>
      </div>
    </>
  );
};

export default CookieBarOptions;

CookieBarOptions.propTypes = {
  preferences: PropTypes.shape().isRequired,
  onUnmount: PropTypes.func.isRequired,
  closeBar: PropTypes.func.isRequired,
};
