import React, { useState, useEffect, useCallback } from 'react';
import Button from '../button';
import { useCookies, useCookieScript } from './cookieHook';
import { useEventListener } from '../../helpers/hooks';
import closeImg from '../../assets/images/close.svg';
import CookieBarContent from './cookieBarContent';
import CookieBarOptions from './cookieBarOptions';
import './cookie_styles.scss';

const CookieBar = () => {
  const [isOpen, setOpen] = useState(false);
  const [preferences, setPreferences] = useCookies();
  const [currentView, switchView] = useState(document.cookie.indexOf('cookiepolicy') === -1 ? 'content' : 'options');
  useEffect(() => {
    if (document.cookie.indexOf('cookiepolicy') === -1) {
      setOpen(true);
    }
  }, []);
  useCookieScript(preferences);

  const updatePreferences = (newPreferences) => {
    setPreferences({
      ...newPreferences,
    });
    setOpen(false);
  };

  const openBar = useCallback(() => {
    setOpen(true);
  }, []);

  useEventListener('click', openBar, document.querySelector('#cookie-center'));
  const acceptAllAndClose = () => {
    setPreferences({
      required: true,
      analytics: true,
      profile: true,
    });
    setOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="cookie-bar cookie-bar-half-mobile">
      <div className="is-relative banner-container">
        <Button text className="cookie-bar__close" onClick={() => { setOpen(false); }}>
          <span className="icon">
            { document.cookie.indexOf('cookiepolicy') !== -1 && <img src={closeImg} alt="close bar" data-action="close" /> }
          </span>
        </Button>
        {currentView === 'content' && <CookieBarContent onAccept={acceptAllAndClose} changeView={switchView} />}
        {currentView === 'options' && <CookieBarOptions closeBar={() => setOpen(false)} onUnmount={updatePreferences} preferences={preferences} />}
      </div>
    </div>
  );
};

export default CookieBar;
